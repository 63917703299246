import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  BsDatepickerDirective,
  BsDaterangepickerContainerComponent,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import {
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  takeUntil,
  tap,
  timeout,
} from 'rxjs';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SearchBarService } from '@modules/car-booking/services/search-bar.service';
import { getRentType, scrollToTop, setRentType } from '@shared/utils/helper';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import {
  RecommendPlace,
  RecommendPlaceRequest,
} from '@modules/car-booking/models/recommendPlaces.model';
import { TranslateService } from '@ngx-translate/core';
import { TIME_OUT_REQUEST } from '@shared/constant';
import {
  ISearchBarData,
  SearchBarDataService,
} from '@modules/car-booking/services/search-bar-data/search-bar-data.service';
import { PlaceType } from '@shared/enum/recommend-place.enum';
import { UserService } from 'src/app/core/services/user/user.service';
import { FooterService } from '@modules/car-booking/services/footer/footer.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { viLocale } from 'src/assets/i18n/vi';
import { DateTime } from 'luxon';
import { CustomDateTimeService } from '@shared/services/custom-date-time.service';
import { DateFormat } from '@shared/enum/date-format.enum';
import { ToastType } from '@shared/enum/toast-type';
import { SearchingPlaceControllerService } from '@assistant/angular-map-location-service';

defineLocale('vi', viLocale);
export type DropdownValue = string | number;

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [MessageService],
})
export class SearchBarComponent implements OnChanges, OnDestroy {
  @ViewChild('addressPanel') addressPanel: OverlayPanel;
  @ViewChild('filterAmountPeoplePanel') filterAmountPeoplePanel: OverlayPanel;
  @ViewChild('rangeCalendarPanel') rangeCalendarPanel: OverlayPanel;
  // @ViewChild('selectOptionRef') selectOptionRef: ElementRef;
  @ViewChild('selectDateCheckIn') selectDateCheckIn!: ElementRef;
  @ViewChild('selectDateCheckout') selectDateCheckout!: ElementRef;
  @ViewChild('rangeCalendarCheckIn') rangeCalendarCheckIn: ElementRef;
  @ViewChild('rangeCalendarCheckOut') rangeCalendarCheckOut: ElementRef;
  @ViewChild('daterangepicker', { static: false })
  daterangepicker: BsDatepickerDirective;
  @ViewChild('contentCalendarPanel', { static: true })
  contentCalendarPanel: ElementRef;
  @ViewChild('bsDaterangepicker', { read: ElementRef })
  bsDaterangepicker: ElementRef;

  @Input() receivedData: any;
  @Input() isMap: boolean;

  @Output() searchBarUpdateData = new EventEmitter<Event>();
  @Output() selectDateValue = new EventEmitter<Event>();

  private searchModelChangeSubscription: Subscription;

  pipe = new DatePipe('en-US');
  destination: string;
  dummyEvent: any = {};
  currentDate: Date = new Date(Date.now());
  maxDate: Date | null;
  control = new FormControl(null);
  startDate: Date;
  tempDateCheckIn: string | null;
  tempDateCheckOut: string | null;
  searchLocation: string = '';
  searchText: string = '';
  searchModelChanged: Subject<string> = new Subject<string>();
  myFormDropdownAge: FormGroup;
  selectedDates: (Date | undefined)[] | undefined;
  valueCheckIn: string | null;
  valueCheckOut: string | null;
  valueSelectDate: Date[];
  isFocused: boolean = false;
  placeholderText: string = 'Enter place';
  // languageSubscription: Subscription;
  yourLocationText: string;
  isSearchByLocation = false;
  addressList: RecommendPlace[] = [];
  isLoading: boolean = false;
  element: any;
  onDestroy$: Subject<void> = new Subject();
  dataSearch: ISearchBarData;
  isClickedInput = false;
  isWithDriver = false;

  rentalDays: any;
  hidenPicker: boolean = false;
  selectedLanguage = localStorage.getItem('user_profile');

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private searchResultService: SearchBarService,
    private soctripTranslationService: SoctripTranslationService,
    private translateService: TranslateService,
    private searchBarDataService: SearchBarDataService,
    private userService: UserService,
    private footerService: FooterService,
    private searchingPlaceControllerService: SearchingPlaceControllerService,
    private renderer: Renderer2,
    private localeService: BsLocaleService,
    private customDateTimeService: CustomDateTimeService
  ) {
    this.isWithDriver = getRentType();
  }

  ngOnInit(): void {
    if (this.selectedLanguage !== null) {
      const userProfile = JSON.parse(this.selectedLanguage);
      if (
        typeof userProfile === 'object' &&
        'language' in userProfile &&
        (userProfile.language as string).toLocaleLowerCase() === 'vi'
      ) {
        this.localeService.use('vi');
      } else {
        this.localeService.use('en');
      }
    } else {
      this.localeService.use('en');
    }

    this._OnInit();
    this.handleSearchPlaces();
    this.placeholderText = 'SEARCH.PLACEHOLDER.PLACE';
    this.yourLocationText = this.soctripTranslationService.getTranslation('SEARCH.YOUR_LOCATION');
  }

  _OnInit() {
    this.footerService.getFooterSetting().subscribe((setting) => {
      this.receivedData.currency = setting.currency;
      this.receivedData.country_code = setting.lang?.country_code;
    });
    this.dataSearch = {
      destination: this.destination,
      rooms: this.receivedData.numberOfRooms,
      check_in: this.receivedData.initCheckInDate!,
      check_out: this.receivedData.initCheckOutDate,
      currency: this.receivedData.currency,
      country_code: this.receivedData.country_code,
      lat: this.receivedData.latitude,
      lon: this.receivedData.longitude,
      place_id: this.receivedData.id,
      geo_type: this.receivedData.geo_type,
    };
    this.searchBarDataService.setSearchData(this.dataSearch);
    this.selectedDates = [this.receivedData.initCheckInDate!, this.receivedData.initCheckOutDate];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['receivedData']) {
      this._OnInit();
    }
  }

  onInputChange(event: Event) {
    if (!this.addressPanel.overlayVisible) {
      this.addressPanel?.show(event);
    }
    const inputSearchElement = event.target as HTMLInputElement;
    this.receivedData.selectedHotel = inputSearchElement.value;
    this.searchModelChanged.next(inputSearchElement.value);
  }

  handleSearchPlaces() {
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((searchText: string) => {
        const params: RecommendPlaceRequest = {
          keyword: searchText,
        };
        this.searchText = searchText;
        this.getRecommendPlaces(params);
        this.isClickedInput = false;

        this.receivedData.latitude = undefined;
        this.receivedData.longitude = undefined;
      });
  }

  getRecommendPlaces(paramsSearch?: RecommendPlaceRequest) {
    this.isLoading = true;
    this.isSearchByLocation = false;
    // const body = {
    //   user_id: this.userService.getUserUuid(),
    // };

    this.searchingPlaceControllerService
      .searchPlace(paramsSearch?.keyword)
      .pipe(
        takeUntil(this.onDestroy$),
        timeout(TIME_OUT_REQUEST),
        tap((res: any) => {
          if (res.success) {
            this.addressList = [];

            res.data.data.forEach((item: any) => {
              let resItem: RecommendPlace = {
                object_name: item.main_text,
                object_type: 'CAR',
                city: 'CITY',
                state: 'STATE',
                country_name: '',
                object_image_url: '',
                num_of_objects: 1,
                property_id: '',
                bg_color: '',
                icon_class: '',
                lat: item.lat,
                lon: item.lon,
                id: item.id,
                geo_type: item.geo_type,
              };
              //let offAddress:any[] = item.adt_divisions.split(',');
              resItem.city = item.adt_divisions;
              // if(offAddress.length>2){
              //   resItem.city = offAddress[0] + ', ' + offAddress[1];
              //   resItem.state = offAddress[1];
              // }
              if (this.addressList.filter((e) => e.object_name === item.main_text).length == 0) {
                this.addressList.push(resItem);
              }
            });
          } else if (!res.success && paramsSearch) {
            this.addressList = [];
          }
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();

    // .subscribe((res:any)=>{
    //   if(res.success){
    //     res.data.forEach((item:any)=>{
    //       let resItem: RecommendPlace = {
    //         object_name: item.main_text,
    //         object_type: 'CAR',
    //         city: '',
    //         state: '',
    //         country_name: item.country,
    //         object_image_url: '',
    //         num_of_objects: 1,
    //         property_id: '',
    //         bg_color: '',
    //         icon_class: '',
    //     }
    //     })

    //   }

    // })
    // this.bookingFamousPlacesControllerService.suggestLocations(
    //     body,
    //     DEFAULT_SEARCH_PARAMS.page_size,
    //     this.receivedData.country_code,
    //     paramsSearch?.keyword
    //   )
    //   .pipe(
    //     takeUntil(this.onDestroy$),
    //     timeout(TIME_OUT_REQUEST),
    //     tap((res: RecommendPlaceResponse) => {
    //       if (res.success) {
    //         this.addressList = this.getPlaceType(res.data!);
    //       } else if (!res.success && paramsSearch) {
    //         this.addressList = [];
    //       }
    //     }),
    //     finalize(() => {
    //       this.isLoading = false;
    //     })
    //   )
    //   .subscribe();
  }

  getPlaceType(places: RecommendPlace[]) {
    return places?.map((address: RecommendPlace) => {
      let icon_class = '';
      let bg_color = '';
      switch (address.object_type) {
        case PlaceType.CITY:
          icon_class = 'sctr-icon-marker-pin-02 text-xl leading-5 text-palette-blue-600';
          bg_color = 'bg-palette-blue-100';
          break;
        case PlaceType.PROPERTY:
          icon_class = 'sctr-icon-building-01 text-xl leading-5 text-palette-blue-600';
          bg_color = 'bg-palette-blue-100';
          break;
        case PlaceType.HISTORY:
          icon_class = 'sctr-icon-clock-fast-forward text-xl leading-5 text-palette-gray-500';
          bg_color = 'bg-palette-gray-200';
          break;
        default:
          break;
      }
      return {
        ...address,
        icon_class,
        bg_color,
      };
    });
  }

  handleCarNearU() {
    try {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        const {
          coords: { longitude, latitude },
        } = position;
        this.yourLocationText =
          this.soctripTranslationService.getTranslation('SEARCH.YOUR_LOCATION');
        this.receivedData.selectedHotel = this.yourLocationText;
        this.dataSearch = {
          ...this.dataSearch,
          destination: this.yourLocationText,
        };
        this.searchBarDataService.setSearchData({
          ...this.dataSearch,
          destination: this.yourLocationText,
        });
        this.receivedData.latitude = latitude;
        this.receivedData.longitude = longitude;

        this.addressPanel.hide();
        this.isSearchByLocation = true;
      });
    } catch (error) {
      throw error;
    }
  }

  
  ngOnDestroy(): void {
    this.searchModelChangeSubscription.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.hideOverlayAddress();
    // this.hideOverlaySelectPeople();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const dateRangePickerInputs = document.querySelectorAll('.date-range-picker');
    let clickedInsidePicker = false;

    const dateRangePickerArray = Array.from(dateRangePickerInputs);
    for (const input of dateRangePickerArray) {
      if (input.contains(targetElement)) {
        clickedInsidePicker = true;
        break;
      }
    }
    if (!clickedInsidePicker) {
      // const element = document.querySelector('.bs-datepicker') as HTMLElement;
      this.daterangepicker.hide();
    }
  }

  onClickInputPlace(event: Event) {
    this.addressPanel.show(event);
    if (!this.isClickedInput) {
      this.isClickedInput = true;
    } else {
      return;
    }
    if (
      (this.isSearchByLocation || this.receivedData.latitude, this.receivedData.longitude) &&
      this.receivedData.selectedHotel === this.yourLocationText
    ) {
      this.receivedData.selectedHotel = '';
      this.getRecommendPlaces();
      return;
    }
    // CASE: Already selected hotel
    if (this.receivedData.selectedHotel) {
      this.getRecommendPlaces({ keyword: this.receivedData.selectedHotel });
      return;
    }
    // CASE: First time click
    if (!this.addressList.length && !this.searchText) {
      this.getRecommendPlaces();
    }
  }

  // Handle change address
  handleInputChange() {
    this.addressPanel.show(this.dummyEvent);
  }

  hideOverlayAddress() {
    this.addressPanel.hide();
  }

  onInputPlaceFocus() {
    this.placeholderText = 'SEARCH.PLACEHOLDER.PLACE';
    this.isFocused = true;
  }

  onHideAddressOverlay() {
    this.isClickedInput = false;
    this.receivedData.selectedHotel =
      this.dataSearch.destination || this.route.snapshot.queryParamMap.get('destination');
  }

  onInputPlaceBlur() {
    this.isFocused = false;
    if (!this.addressList.length) return; // if not have data and click outside
    if (!this.receivedData.selectedHotel) {
      this.placeholderText = 'SEARCH.PLACEHOLDER.PLACE';
      return;
    }
    if (this.isSearchByLocation) {
      this.dataSearch = {
        ...this.dataSearch,
        destination: this.yourLocationText,
      };
      this.searchBarDataService.setSearchData({
        ...this.dataSearch,
        destination: this.yourLocationText,
      });
      this.receivedData.selectedHotel = this.yourLocationText;
      return;
    }
    this.dataSearch = {
      ...this.dataSearch,
      destination: this.addressList?.[0]?.object_name,
    };
    this.searchBarDataService.setSearchData({
      ...this.dataSearch,
      destination: this.addressList?.[0]?.object_name,
    });
    this.receivedData.property_id = this.addressList?.[0]?.property_id;
    this.receivedData.dest_type = this.addressList?.[0]?.property_id
      ? PlaceType.PROPERTY.toUpperCase()
      : PlaceType.CITY.toUpperCase();
  }

  onDateRangeChange(event: any) {
    if (event && DateTime.fromJSDate(event[0]).isValid && DateTime.fromJSDate(event[1]).isValid) {
      if (DateTime.fromJSDate(event[0]).valueOf() === DateTime.fromJSDate(event[1]).valueOf()) {
        this.valueSelectDate = event;
        this.startDate = event[0];
        this.selectDateValue.emit(event);
        this.valueCheckIn = this.formatDateTimeTemplate(event[0]);
        this.valueCheckOut = this.formatDateTimeTemplate(event[0]);
        this.tempDateCheckIn = this.formatDateTimeTemplate(event[0]);
        this.tempDateCheckOut = this.formatDateTimeTemplate(event[0]);

        this.maxDate = null;
        this.rentalDays = DateTime.fromJSDate(event[1])
          .diff(DateTime.fromJSDate(event[0]), 'days')
          .days.toFixed(0);
        this.dataSearch = {
          ...this.dataSearch,
          check_in: this.formatDateTimeTemplate(event[0]),
          check_out: this.formatDateTimeTemplate(event[0]),
        };
        this.searchBarDataService.setSearchData({
          ...this.dataSearch,
          check_in: this.formatDateTimeTemplate(event[0]),
          check_out: this.formatDateTimeTemplate(event[0]),
        });
        return;
        // this.messageService.add({
        //   severity: ToastType.ERROR,
        //   detail: this.soctripTranslationService.getTranslation(
        //     'MESSAGE.CHECK_IN_AND_CHECKOUT_MUST_DIFFERENT'
        //   ),
        // });
        // this.valueCheckIn = null;
        // this.valueCheckOut = null;
        // this.tempDateCheckIn = null;
        // this.tempDateCheckOut = null;
        // this.hidenPicker = false;
        // this.dataSearch = {
        //   ...this.dataSearch,
        //   check_in: null,
        //   check_out: null,
        // };
        // this.searchBarDataService.setSearchData({
        //   ...this.dataSearch,
        // });
        // return;
      }

      this.valueSelectDate = event;
      this.startDate = event[0];
      this.selectDateValue.emit(event);
      this.valueCheckIn = this.formatDateTimeTemplate(event[0]);
      this.valueCheckOut = this.formatDateTimeTemplate(event[1]);
      this.tempDateCheckIn = this.formatDateTimeTemplate(event[0]);
      this.tempDateCheckOut = this.formatDateTimeTemplate(event[1]);
      this.maxDate = null;
      this.rentalDays = DateTime.fromJSDate(event[1])
        .diff(DateTime.fromJSDate(event[0]), 'days')
        .days.toFixed(0);
      this.dataSearch = {
        ...this.dataSearch,
        check_in: this.formatDateTimeTemplate(event[0]),
        check_out: this.formatDateTimeTemplate(event[1]),
      };
      this.searchBarDataService.setSearchData({
        ...this.dataSearch,
        check_in: this.formatDateTimeTemplate(event[0]),
        check_out: this.formatDateTimeTemplate(event[1]),
      });
    } else {
      this.valueCheckIn = null;
    }
    this.hidenPicker = false;
  }

  customerFooter() {
    const footerHTML = `
      <div class="flex gap-[8px] w-full justify-between items-center">
        <div class="flex flex-col justify-between">
          <p class="font-semibold">
            ${this.valueCheckIn} - ${
      this.valueCheckOut !== null
        ? this.valueCheckOut
        : this.soctripTranslationService.getTranslation('SEARCH.DATE_PICKET_SELECT_END_DATE')
    }
          </p>
          <p class="flex items-center text-sm gap-1">
            <span>${this.soctripTranslationService.getTranslation(
              'SEARCH.DATE_PICKET_RENTALDAYS'
            )}:</span>
            <span>${this.soctripTranslationService.getTranslationWithParam(
              'pluralization.total_days',
              { count: this.rentalDays }
            )}</span>
            <span class="tooltip"></span>
          </p>
        </div>
      </div>
    `;
    // <div class="wrap-btn">
    //   <button class="text-white rounded-lg bg-[#2563eb] py-3 px-5" id="continue-btn">${this.translateService.instant('SEARCH.DATE_PICKET_CONTINUE')}</button>
    // </div>
    const element = document.querySelector('.bs-datepicker') as HTMLElement;
    element.style.display = 'block';
    const elements = document.querySelector('bs-daterangepicker-container') as HTMLElement;

    const existingFooter = document.querySelector('.custom-datepicker-footer') as HTMLElement;
    if (existingFooter) {
      this.renderer.removeChild(element, existingFooter);
    }

    const footer = this.renderer.createElement('div');
    this.renderer.addClass(footer, 'custom-datepicker-footer');
    this.renderer.setProperty(footer, 'innerHTML', footerHTML);
    this.renderer.appendChild(element, footer);
    const continueBtn = document.querySelector('#continue-btn') as HTMLElement;
    if (continueBtn) {
      this.renderer.listen(continueBtn, 'click', () => this.onContinueClick());
    }
    elements.style.zIndex = this.receivedData.zIndexCalendar;
  }

  onContinueClick() {
    this.hidenPicker = true;
    this.daterangepicker.hide();
  }

  // ngAfterViewInit() {
  //   this.daterangepicker.bsValueChange.subscribe((date: Date[]) => {
  //     setTimeout(() => {
  //       this.daterangepicker.show();
  //       this.customerFooter()
  //     });
  //   });
  // }

  showCalendarDayCheckIn(event: Event) {
    this.daterangepicker.hide();
    this.daterangepicker.minDate = new Date(Date.now());
    this.daterangepicker.show();
    this.customerFooter();
  }

  showCalendarDayCheckOut(event: Event) {
    this.daterangepicker.show();
    this.customerFooter();
  }

  onDatepickerHide() {
    if (this.valueCheckIn && !this.valueCheckOut) {
      this.valueCheckOut = this.valueCheckIn;
      this.tempDateCheckIn = this.valueCheckIn;
      this.tempDateCheckOut = this.valueCheckIn;
      this.selectedDates = [this.currentDate, this.currentDate];

    } else {
      this.valueCheckIn = this.tempDateCheckIn;
      this.valueCheckOut = this.tempDateCheckOut;
    }
    this.maxDate = null;
    this.currentDate = new Date(Date.now());
    // if(!this.hidenPicker){
    //   this.daterangepicker.show();
    //   this.customerFooter()
    // }
  }

  onDatepickerShown(): void {
    this.selectDateCheckIn.nativeElement.focus();
    const datePickerInstance: BsDaterangepickerContainerComponent =
      this.daterangepicker['_datepicker'].instance;
    datePickerInstance.valueChange
      .pipe(filter((value) => value && value[0] && !value[1]))
      .subscribe((value: Date[]) => {
        if (value) {
          const [startDate] = value;
          this.valueCheckIn = this.formatDateTimeTemplate(startDate);
          this.valueSelectDate = [startDate, startDate];
          
          this.currentDate = startDate;
          this.maxDate = DateTime.fromJSDate(startDate).plus({ days: 90 }).toJSDate();
          this.dataSearch = {
            ...this.dataSearch,
            check_in: this.formatDateTimeTemplate(startDate),
            check_out: this.formatDateTimeTemplate(startDate),
          };
          this.searchBarDataService.setSearchData({
            ...this.dataSearch,
            check_in: this.formatDateTimeTemplate(startDate),
            check_out: this.formatDateTimeTemplate(startDate),
          });
          this.selectDateValue.emit(this.valueSelectDate as any);
          this.selectDateCheckout.nativeElement.focus();
          this.valueCheckOut = null;
          this.customerFooter();
        }
      });
  }

  onHotelSelected(address: RecommendPlace) {
    this.receivedData.dest_type = address.property_id
      ? PlaceType.PROPERTY.toUpperCase()
      : PlaceType.CITY.toUpperCase();
    this.receivedData.property_id = address.property_id;
    this.receivedData.latitude = address.lat;
    this.receivedData.longitude = address.lon;
    this.receivedData.id = address.id;
    this.receivedData.geo_type = address.geo_type;
    this.dataSearch = {
      ...this.dataSearch,
      destination: address.object_name,
      place_id: address.id,
      geo_type: address.geo_type,
    };
    this.searchBarDataService.setSearchData({
      ...this.dataSearch,
      // destination: address.object_name,
    });
    this.hideOverlayAddress();
  }

  convertDateDefault(date: any) {
    const originalDate = new Date(date);

    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  // Handle search
  onClickSearch() {
    if (!this.valueCheckIn || !this.valueCheckOut) {
      this.messageService.add({
        severity: ToastType.ERROR,
        detail: this.soctripTranslationService.getTranslation(
          'MESSAGE.CHECK_IN_AND_CHECKOUT_REQUIRED'
        ),
      });
      return;
    }
    if (
      this.receivedData.selectedHotel === undefined ||
      this.receivedData.selectedHotel?.trim().length === 0
    ) {
      this.messageService.add({
        severity: ToastType.ERROR,
        detail: this.soctripTranslationService.getTranslation('MESSAGE.ENTER_PLACE_OR_HOTEL'),
      });
      return;
    } else {
      // this.convertAgeListDefault();
      scrollToTop('smooth');
      const value = this.getValueSearchParams();
      this.searchResultService.setItemStorage('data-search-result', value);
      // this.ageDropdownData.emit(this.ageDropdownList);
      this.searchBarUpdateData.emit(this.receivedData);
    }
  }

  getValueSearchParams() {
    const checkInDate = this.pipe.transform(this?.valueSelectDate[0], 'yyyy-MM-dd');
    const checkOutDate = this.pipe.transform(this.valueSelectDate[1], 'yyyy-MM-dd');
    if (!this.receivedData.latitude || !this.receivedData.longitude) {
      this.receivedData = {
        ...this.receivedData,
        selectedHotel: this.addressList?.[0]?.object_name,
        latitude: this.addressList?.[0]?.lat,
        longitude: this.addressList?.[0]?.lon,
      };
    }
    let value = {
      ...this.receivedData,
      destination_name: this.receivedData.selectedHotel,
      destination: this.receivedData.selectedHotel,
      country_code: this.receivedData.country_code,
      checkin: checkInDate!,
      checkout: checkOutDate!,
      currency: this.receivedData.currency,
      property_id: this.receivedData.property_id,
      page_num: 0,
      isWithDriver: this.isWithDriver,
    };
    setRentType(this.isWithDriver);
    if (
      this.isSearchByLocation ||
      this.receivedData.selectedHotel === this.translateService.instant('SEARCH.YOUR_LOCATION')
    ) {
      value = Object.assign(
        {
          latitude: this.receivedData?.latitude,
          longitude: this.receivedData?.longitude,
        },
        value
      );
    }
    return value;
  }

  setDataSearch() {
    const value = this.getValueSearchParams();
    this.searchResultService.setQueryParamData(value);
    return value;
  }
  formatDateTimeTemplate(value: any, time?: string) {
    if (time) {
      return this.customDateTimeService.formatDate(value, DateFormat.DATE_SHORT) + ` ${time}`;
    }
    return this.customDateTimeService.formatDate(value, DateFormat.DATE_SHORT);
  }
}
