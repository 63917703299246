import { Component, inject, OnInit } from '@angular/core';
import { MapSearchService } from '@modules/car-booking/services/map-search/map-search.service';
import { CarDetail } from '@modules/car-booking/models/view-details';
import { createSlug } from '@shared/utils/helper';
import { ActivatedRoute, Router } from '@angular/router';
import { BookMarkService } from '@modules/car-booking/services/bookmark/bookmark.service';
import { BookmarkControllerService } from '@soctrip/angular-bookmarks-service';
import { TranslateService } from '@ngx-translate/core';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { SearchRoomControllerService } from '@booking/booking-property-search-service';
import { SearchBarService } from '@modules/car-booking/services/search-bar.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { DEFAULT_SEARCH_PARAMS } from '@shared/constant';
import { min } from 'lodash';
import { take } from 'rxjs';
import { environment } from '@env/environment';
import { LocalizedLangDescStringPipe } from '@shared/pipes/localized-lang-desc-string.pipe';

const { BloomFilter } = require('@soctrip-common/soctrip-algorithm/dist/api.js');
@Component({
  selector: 'map-marker-popup',
  templateUrl: './map-marker-popup.component.html',
  styleUrls: ['./map-marker-popup.component.scss'],
})
export class MapMarkerPopupComponent implements OnInit {
  markerData: CarDetail;
  distanFromCenterText: string = '';
  isBookmark: boolean;
  netPrice: number;
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;
  queryParams: any;
  baseUrl = environment.BASE_PATH;
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;

  localizedLangDescStringPipe = inject(LocalizedLangDescStringPipe);

  constructor(
    private mapSearchService: MapSearchService,
    private route: ActivatedRoute,
    private router: Router,
    private bookMarkService: BookMarkService,
    private bookmarkControllerService: BookmarkControllerService,
    private translateService: TranslateService,
    private searchRoomControllerService: SearchRoomControllerService,
    private searchResultService: SearchBarService,
    private userService: UserService
  ) {
    this.searchResultService.getQueryParamData().subscribe((res) => {
      this.queryParams = res;
    });
  }

  ngOnInit(): void {
    this.getMarker();
    this.bookMarkService.getBloomFilter();
    this.checkBookmark();
  }

  getRoomPrice() {
    const body = { user_id: this.userService.getUserUuid() };
    this.searchRoomControllerService
      .getRoomsByProperty(
        DEFAULT_SEARCH_PARAMS.checkin,
        DEFAULT_SEARCH_PARAMS.checkout,
        DEFAULT_SEARCH_PARAMS.num_of_rooms,
        DEFAULT_SEARCH_PARAMS.num_of_children,
        DEFAULT_SEARCH_PARAMS.num_of_adults,
        DEFAULT_SEARCH_PARAMS.currency,
        this.queryParams?.property_id,
        body,
        DEFAULT_SEARCH_PARAMS.page_num,
        DEFAULT_SEARCH_PARAMS.page_size
      )
      .subscribe((res) => {
        if (res.success) {
          const priceArr = res.data?.room_types?.map((data: any) => data.net_price);
          this.netPrice = min(priceArr)!;
        }
      });
  }

  getMarker() {
    this.queryParams?.property_id && this.getRoomPrice();
    this.mapSearchService
      .getMarker()
      .pipe(take(1))
      .subscribe((res) => {
        this.distanFromCenterText =
          `${res.distance_from_center} ` + this.translateService.instant('DETAIL.KM_FROM_CENTRE');
        this.markerData = res;
      });
  }

  handleBookmark(id: string) {
    if (!this.bookMarkService.isAllow) {
      alert('Please login before bookmark');
    } else {
      if (this.isBookmark) {
        this.bookMarkService.deleteBookmark(id).subscribe({
          next: (res: any) => {
            if (res.success) this.isBookmark = false;
          },
        });
      } else {
        this.bookMarkService.createBookMark(id).subscribe({
          next: (res: any) => {
            if (res.success) this.isBookmark = true;
          },
        });
      }
    }
  }

  checkBookmark() {
    this.bookMarkService.apiSuccess$.subscribe({
      next: (success) => {
        if (success) {
          let bloomFilter = BloomFilter.load(this.bookMarkService.bloomFilter);
          const exists = bloomFilter.has(this.markerData.id);
          if (exists) {
            this.bookmarkControllerService
              .bookmarksObjectIdIsExistedGet(this.markerData.id)
              .subscribe({
                next: (res) => {
                  if (res.data.is_existed) {
                    this.isBookmark = true;
                  }
                },
              });
          } else {
            this.isBookmark = false;
          }
        }
      },
    });
  }

  viewDetail(id: string, name: any, address: string) {
    const addressArray = address.split(',');
    const queryParams = { ...this.route.snapshot.queryParams, property_id: id };
    name = this.localizedLangDescStringPipe.transform(name);
    this.router.navigate(
      ['/booking/car/search', createSlug(addressArray[addressArray.length - 1]), createSlug(name)],
      { queryParams }
    );
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/test/default-image.jpg';
  }
}
