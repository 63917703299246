import { TotalPaymentService } from './../../../modules/car-booking/services/total-payment.service';
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  Renderer2,
  OnInit,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastType } from '@shared/enum/toast-type';
import { Policy } from '@modules/car-booking/models/hotel-result.model';
import {
  HotelSearchRequest,
  IProperty,
  PropertyAmenityList,
} from '@modules/car-booking/models/hotel-search.model';
import { createSlug } from '@shared/utils/helper';
import { ActivatedRoute, Router } from '@angular/router';
import { BookMarkService } from '@modules/car-booking/services/bookmark/bookmark.service';
import { BookmarkControllerService } from '@soctrip/angular-bookmarks-service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { QuickViewHotelPhotoModal } from '../quick-view-hotel-photo/quick-view-hotel-photo.component';
import { environment } from '@env/environment';
import { ICarItem } from '@modules/car-booking/models/hotel.model';
import { TranslateService } from '@ngx-translate/core';

const { BloomFilter } = require('@soctrip-common/soctrip-algorithm/dist/api.js');
@Component({
  selector: 'app-hotel-result-card',
  templateUrl: './hotel-result-card.component.html',
  providers: [MessageService],
  styleUrls: ['./hotel-result-card.component.scss'],
})
export class HotelResultCardComponent implements OnInit, AfterViewInit {
  @ViewChild('tag', { static: true }) listContainer: ElementRef;
  @ViewChild('policy', { static: true }) listPolicy: ElementRef;
  @ViewChildren('hotelPolicy') hotelPolicyElements: QueryList<ElementRef>;
  @ViewChildren('hotelName') hotelNameElements: QueryList<ElementRef>;
  @ViewChildren('hotelfacility') hotelFacilityElements: QueryList<ElementRef>;
  @ViewChildren('hotelAddress') hotelAddressElements: QueryList<ElementRef>;
  @ViewChildren('hotelDistance') hotelDistanceElements: QueryList<ElementRef>;
  @Input() item: ICarItem;

  public hotelPolicyArray: any;
  public hotelFacilityArray: any;
  public hotelNameArray: any;
  public hotelAddressArray: any;
  public hotelDistanceArray: any;

  public isBookmark: boolean;
  public remainingFacility: number | string;
  public hasRemainingFacility: boolean = false;
  public remainingPolicy: number | string;
  public hasRemainingPolicy: boolean = false;
  public isPrice: boolean = false;
  public isVote: boolean = false;
  public sliceFacility: string;
  public slicePolicy: string;

  private resizeTimeout: any;
  public itemTagFacility: number[] = [];
  public itemTagPolicy: number[] = [];
  public displayFacility: PropertyAmenityList[];
  public displayPolicy: Policy[] = [];

  public showScoreTooltip = false;
  public showStarTooltip = false;
  ref: DynamicDialogRef | undefined;
  listImages: string[] = [];
  baseUrl = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
  visible: boolean;
  userCurrency: string;
  mobileView: boolean = false;
  constructor(
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private bookMarkService: BookMarkService,
    private bookmarkControllerService: BookmarkControllerService,
    private totalPaymentService: TotalPaymentService,
    public dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.userCurrency =
      JSON.parse(localStorage.getItem(environment.CURRENCY_CONVERSATION_DATA) || '')?.currency ||
      'USD';
    // this.item.room_amenity_list.filter((item: any) => {
    //   if (item.amenity_name === 'Spa') {
    //     item.amenity_icon = 'Spa';
    //   }
    // });
    // this.displayFacility = this.item.room_amenity_list;
    this.calculatePolicy();
    this.checkBookmark();
    if (window.innerWidth <= 768) {
      this.mobileView = true;
    }
  }

  ngAfterViewInit(): void {
    //this.calculateSumItem(this.itemTagFacility, this.listContainer);
    //this.calculateSumItem(this.itemTagPolicy, this.listPolicy);
    //this.updateViewFacility(1.7);
    //this.updateViewPolicy(1);
    this.cdRef.detectChanges();
  }
  public calculatePolicy() {
    this.displayPolicy.push({
      name: this.translateService.instant(
        this.item?.is_required_collateral
          ? 'SEARCH_RESULT.car_list.policy.collateral_required'
          : 'SEARCH_RESULT.car_list.policy.no_collateral'
      ),
      type: 'warning',
    });
    if (this.item.is_self_pickup) {
      this.displayPolicy.push({
        name: this.translateService.instant('SEARCH_RESULT.car_list.policy.self_pickup'),
        type: 'info',
      });
    }

    if (this.item.is_home_delivery) {
      this.displayPolicy.push({
        name: this.translateService.instant('SEARCH_RESULT.car_list.policy.home_delivery'),
        type: 'error',
      });
    }
  }

  public calculateSumItem(itemTag: number[], el: ElementRef) {
    const frameTag = el.nativeElement;
    const tags = frameTag.children;
    let sumSizeTag = 0;
    for (let index = 0; index < tags.length; index++) {
      const tag = tags[index];
      if (tag.nodeType === Node.ELEMENT_NODE) {
        const childElement = tag as HTMLElement;
        sumSizeTag += childElement.offsetWidth;
        itemTag.push(sumSizeTag);
      }
    }
  }
  isDisableBookmark = false;
  handleBookmark(event: any, id: string) {
    if (this.isDisableBookmark) {
      return;
    }
    event.stopPropagation();
    if (!this.bookMarkService.isAllow) {
      this.messageService.add({
        severity: ToastType.WARNING,
        detail: 'Please login before bookmark',
      });
    } else {
      this.isDisableBookmark = true;
      if (this.isBookmark) {
        this.bookMarkService.deleteBookmark(id).subscribe({
          next: (res: any) => {
            if (res.success) {
              this.isBookmark = false;
              this.messageService.add({
                severity: ToastType.SUCCESS,
                detail: this.translateService.instant('toast_save_car.unsave_success', {
                  param: this.item.vehicle_info?.car_model_name,
                }),
              });
            }
          },
          error: (err) => {
            console.log(err);
          },
          complete: () => {
            this.isDisableBookmark = false;
          },
        });
      } else {
        this.bookMarkService.createBookMark(id).subscribe({
          next: (res: any) => {
            if (res.success) {
              this.isBookmark = true;
              this.messageService.add({
                severity: 'success',
                detail: this.translateService.instant('toast_save_car.save_success', {
                  param: this.item.vehicle_info?.car_model_name,
                }),
                key: 'save',
              });
            }
          },
          error: (err) => {
            console.log(err);
          },
          complete: () => {
            this.isDisableBookmark = false;
          },
        });
      }
    }
  }

  getPropertyImageId(item: ICarItem) {
    return item.avatar
      ? `${this.baseUrl}/${item?.avatar.id}.webp`
      : 'assets/images/test/default-hotel.svg';
  }

  checkBookmark() {
    this.bookMarkService.apiSuccess$.subscribe({
      next: (success) => {
        if (success) {
          let bloomFilter = BloomFilter.load(this.bookMarkService.bloomFilter);
          const exists = bloomFilter.has(this.item.id);
          if (exists) {
            this.bookmarkControllerService.bookmarksObjectIdIsExistedGet(this.item.id).subscribe({
              next: (res) => {
                if (res.data.is_existed) {
                  this.isBookmark = true;
                }
              },
            });
          } else {
            this.isBookmark = false;
          }
        }
      },
    });
  }

  public showAllFacility(): void {
    //this.displayFacility = this.item.room_amenity_list;
  }

  public showAllPolicy(): void {
    this.displayPolicy = [];
  }

  public updateViewPolicy(size: number) {
    // const frameTag = this.listPolicy.nativeElement.offsetWidth;
    // let breakIndex = 1;
    // for (let index = 0; index < this.itemTagPolicy.length; index++) {
    //   if ((frameTag * size) < this.itemTagPolicy[index]) {
    //     this.displayPolicy = this.displayPolicy.slice(0, index);
    //     this.slicePolicy = this.displayPolicy.slice(index).map((item) => item.name).join(', ');
    //     this.hasRemainingPolicy = true;
    //     this.remainingPolicy = `+${this.displayPolicy.slice(index).length}`;
    //     breakIndex += 1;
    //     break;
    //   }
    // }
    // if (breakIndex === 1) {
    //   this.showAllPolicy();
    //   this.hasRemainingPolicy = false;
    // }
  }

  public updateViewFacility(size: number) {
    const frameTag = this.listContainer?.nativeElement.offsetWidth;
    let breakIndex = 1;
    // for (let index = 0; index < this.itemTagFacility.length; index++) {
    //   if ((frameTag * size) < this.itemTagFacility[index]) {
    //     this.displayFacility = this.item.room_amenity_list.slice(0, index);
    //     this.sliceFacility = this.item.room_amenity_list.slice(index).map((item) => item.amenity_name).join(', ');
    //     this.hasRemainingFacility = true;
    //     this.remainingFacility = `+${this.item.room_amenity_list.slice(index).length}`;
    //     breakIndex += 1;
    //     break;
    //   }
    // }
    // if (breakIndex === 1) {
    //   this.showAllFacility();
    //   this.hasRemainingFacility = false;
    // }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (
      (window.innerWidth <= 1560 && window.innerWidth >= 1280) ||
      (window.innerWidth <= 1080 && window.innerWidth >= 768) ||
      (window.innerWidth <= 768 && window.innerWidth >= 0)
    ) {
      this.isPrice = true;
    } else this.isPrice = false;
    if (window.innerWidth <= 768 && window.innerWidth >= 0) {
      this.isVote = true;
      this.mobileView = true;
    } else {
      this.isVote = false;
      this.mobileView = false;
    }
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.updateViewFacility(1.7);
      this.updateViewPolicy(1);
    }, 400);
  }

  public handleShowTooltip(els: ElementRef[], className: string): void {
    for (var val of els) {
      const isTruncated = val.nativeElement.scrollWidth > val.nativeElement.clientWidth;
      if (isTruncated) {
        this.renderer.addClass(val.nativeElement, className);
      } else {
        this.renderer.removeClass(val.nativeElement, className);
      }
    }
  }

  public mouseOverHotelName() {
    this.hotelNameArray = this.hotelNameElements.toArray();
    this.handleShowTooltip(this.hotelNameArray, 'hotel-name');
  }

  public mouseOverHotelFacility() {
    this.hotelFacilityArray = this.hotelFacilityElements.toArray();
    if (this.remainingFacility) {
      for (var val of this.hotelFacilityArray) {
        this.renderer.addClass(val.nativeElement, 'hotel-facility');
      }
    }
  }

  public mouseOverHotelPolicy() {
    this.hotelPolicyArray = this.hotelPolicyElements.toArray();
    if (this.remainingPolicy) {
      for (var val of this.hotelPolicyArray) {
        this.renderer.addClass(val.nativeElement, 'hotel-policy');
      }
    }
  }

  public mouseOverHotelAddress() {
    this.hotelAddressArray = this.hotelAddressElements.toArray();
    this.handleShowTooltip(this.hotelAddressArray, 'hotel-address');
  }
  public mouseOverHotelDistance() {
    this.hotelDistanceArray = this.hotelDistanceElements.toArray();
    this.handleShowTooltip(this.hotelDistanceArray, 'hotel-distance');
  }

  public handleClickView(event: Event) {
    event.stopPropagation();
    this.messageService.add({
      severity: ToastType.WARNING,
      detail: 'This feature is not developed yet',
    });
  }

  convertDoubleToInt(num: any) {
    return num | 0;
  }

  public formatCurrency(price: number) {
    return price?.toLocaleString('en-US');
  }

  viewDetail(id: string, name: string, item: any) {
    const addressArray = item.location.district.name.split(',');
    const queryParams = { ...this.route.snapshot.queryParams, property_id: id };
    this.router.navigate(
      ['/booking/car/search', createSlug(addressArray[addressArray.length - 1]), createSlug(name)],
      { queryParams }
    );
  }
  detailLink(name: string, item: any) {
    const addressArray = item.location.district.name.split(',');
    return `/booking/car/search/${createSlug(addressArray[addressArray.length - 1])}/${createSlug(
      name
    )}`;
  }
  detailParams(id: string) {
    const queryParams = { ...this.route.snapshot.queryParams, property_id: id };
    return queryParams;
  }

  viewMap(item: ICarItem) {
    const queryParams = {
      property_id: item.id,
    };
    this.router.navigate(['/booking/car/map', this.item.description], {
      queryParams,
    });
  }

  convertImgIdsToImages(images: string[]) {
    return images.map((image) => this.baseUrl + '/storage/files/web/' + image + '.webp');
  }

  quickViewPhotos(event: Event, images: string[]) {
    event.stopPropagation();
    this.ref = this.dialogService.open(QuickViewHotelPhotoModal, {
      data: {
        selectedImage: images,
      },
      showHeader: false,
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      dismissableMask: true,
    });
    this.ref.onClose.subscribe((data: any) => {});
    this.ref.onMaximize.subscribe((value: any) => {});
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/test/default-hotel.svg';
  }

  openDialog() {
    this.visible = true;
  }
  closeDialog() {
    this.visible = false;
  }
}
