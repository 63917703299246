export const BOOKING = {
  BOOKING_HOTEL: {
    BOOKING_HOTEL: 'booking/car',
    SEARCH_HOTEL: 'booking/car/search',
    FEATURE_HOTEL: 'booking/car/featured-hotels',
    HOT_DEAL: 'booking/car/hot-deals',
    MAP: 'booking/car/map',
    PAYMENT_HOTEL: 'booking/car/payment',
  },
};

export const MY_BOOKING = {
  MY_BOOKING: 'my-booking',
};

export const CAR = {
  CAR_RATING: 'car-rating',
};

export const CAR_OTA = {
  CAR_OTA: 'car-ota',
  AGENCY: 'agency',
  AGENCY_CENTER: 'agency-center',
  BECOME_AGENCY: 'become-agency',
  SUCCESS: 'success',
  CREATE: 'create',
  DASHBOARD: 'dashboard',
  CAR_MANAGEMENT: 'car-management',
  USER_MANAGEMENT: 'user-management',
  RESERVATION_MANAGEMENT: 'reservation-management',
  AVAILABILITY: 'availability',
  PROMOTIONS: 'promotions',
  DETAILS: 'details',
  ADD: 'add',
  EDIT: 'edit',
  OTA_ID: 'otaId',
  PROMOTION_ID: 'promotionId',
  PROFILE: 'profile',
  CONTRACT: 'contract',
  REVIEW_MANAGEMENT: 'review-management',
  AGENCY_SUPPORT: 'agency-support',
  ACCOUNT_BALANCE: 'account-balance',
};

export const OTA = {
  OTA: 'ota',
  HOTEL_OTA: {
    HOTEL_OTA: 'ota/hotel-ota',
    BECOME_AGENCY: 'ota/become-an-agency',
    HOTEL_AGENCY: 'ota/become-an-agency/hotel-agency',
    ACCOMMODATION_CATEGORY: 'accommodation-category',
    PROPERTY_INFO: 'ota/become-an-agency/hotel-agency/property-info',
    GENERAL_INFO: 'general-info',
    PROPERTY_AMENITY: 'property-amenities',
    PROPERTY_PHOTO: 'property-photos',
    PROPERTY_POLICY: 'property-policies',
    PAYMENT_INFO: 'payment-info',
    ROOM: 'room-info/rooms',
    ADD_ROOM: 'room-info/rooms/add-new',
    EDIT_ROOM: 'room-info/rooms/edit',
    REVIEW_INFO: 'room-info/review-info',
    PREVIEW_PROPERTY: 'preview-property',
    CONTRACT_INFO: 'contract-agreement/contract-info',
    REVIEW_CONTRACT: 'contract-agreement/review-contract',
  },
  HOTEL_MANAGEMENT: {
    HOTEL_MANAGEMENT: 'ota/hotel-ota/hotel-management',
    DASHBOARD: 'dashboard',
    GENERAL_INFO: 'property-management/general-info',
    PROPERTY_AMENITY: 'property-management/property-amenities',
    PROPERTY_PHOTO: 'property-management/property-photos',
    PROPERTY_POLICY: 'property-management/property-policies',
    PAYMENT_INFO: 'property-management/payment-info',
    ROOM_INFO: 'room-info',
    EDIT_ROOM: 'room-info/edit',
    ADD_ROOM: 'room-info/add',
    RATE_PLAN: 'rate-plans/rate-plans',
    AVAILABILITY: 'rate-plans/availability',
    PROMOTION: 'rate-plans/promotion',
    MANAGE_RESERVATIONS: 'manage-reservations',
    RESERVATION_DETAILS: 'reservation-details',
    USER_MANAGEMENT: 'user-management',
  },
};

export const ADMIN = {
  CAR_APPROVAL: 'car-approval',
};
