import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { UnexpectedResponseException } from 'pdfjs-dist/types/src/shared/util';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimezoneService {
  private timezoneMappingJson = require('@shared/constants/mapping-timezone.json');

  getTimezoneMapping(key: string) {
    return this.timezoneMappingJson[key] ?? this.timezoneMappingJson['US'];
  }
}
