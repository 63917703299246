<div id="menu-bar" [ngClass]="handleMenuBar()" class="fixed top-0 z-[1002] w-full">
  <lib-header
    [environment]="environment"
    [userInfo]="userInfo"
    [currentPage]="currentPage"
    [myBookingUrl]="myBookingUrl"
    [visibleShopingCart]="false"
    [visibleSearch]="false"
    [(megaVisible)]="megaVisible"
    [loginReturnUrl]="environment.CAR_RENTAL_URL"
    (updateLanguage)="changeLanguage($event)"
    [isShowRemindOrder]="isAllowedToShowRemindOrder()"
    [module]="'CAR'"
    [isCrossModule]="false"
    [anonymousUserId]="anonymousUserId"
    [remindPositionSetting]="remindPositionSetting"
    [stickyBannerCode]="'CAR_STICKY_BANNERS'"
    [popUpBannerCode]="'CAR_POPUP_BANNERS'"
    [countryCode]="countryCode"
    [isShowBanner]="true"
    [timezone]="currentTimeZone"
    class="text-gray-900"
    [floatBtnClass]="'top-[calc(100vh_-_40vh)] right-5'"
  ></lib-header>
</div>
