<p-dialog
  [(visible)]="visible"
  [style]="{ width: '708px', 'min-height': '70vh', 'max-height': 'auto' }"
  [modal]="true"
  class="custom-dialog t-post-dialog"
  [draggable]="false"
  [resizable]="false"
  (onHide)="onDialogHide()"
  (onShow)="onShowDialog()"
>
  <ng-template pTemplate="header" class="relative">
    <div class="flex w-full items-center gap-[18px]">
      <span class="font-inter text-xl font-semibold text-palette-gray-900">
        {{ 'POST.CREATE_PORT' | soctripTranslate | async }}
      </span>
    </div>
    <div class="background-blob-left absolute right-14 top-[0]">
      <img src="assets/icons/booking_detail/vector.svg" />
    </div>
    <div class="background-blob-left absolute right-0 top-14 z-0">
      <img src="assets/icons/booking_detail/vector-2.svg" />
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="px-0 py-0">
      <form [formGroup]="postForm" (ngSubmit)="onSubmit()" id="postForm">
        <div class="p-3 pt-0">
          <div class="border-pale flex w-full justify-between gap-2 rounded-xl border bg-white p-3">
            <div class="box-avatar relative h-10 min-h-[40px] w-10 min-w-[40px] max-w-[40px]">
              <div class="flex h-full w-full items-center justify-center rounded-full">
                <img
                  class="h-[34px] min-h-[34px] w-[34px] min-w-[34px] rounded-full"
                  [src]="userInfo.avatar_thumbnail_url | ImagePath : 'thumbnail'"
                  (error)="replaceAvatarWithDefaultImage($event)"
                  alt=""
                />
              </div>
              <div
                class="absolute right-0 top-0 -mr-1 flex max-h-[12px] min-h-[12px] min-w-[12px] max-w-[12px] items-center justify-center rounded-full bg-white"
              >
                <img
                  class="max-h-[9px] min-h-[9px] min-w-[9px] max-w-[9px]"
                  src="assets/images/home/icon/tier.svg"
                  alt=""
                />
              </div>
            </div>
            <textarea
              class="ml-2 h-24 w-full resize-none self-start font-inter text-md focus:outline-none"
              formControlName="content"
              id="content"
              placeholder="{{
                'POST.HI_SHARE_YOUR_FEEL'
                  | soctripTranslate : { userName: userInfo.full_name }
                  | async
              }}"
            ></textarea>
            <div class="relative flex items-end">
              <i
                class="sctr-icon-face-smile hover:text-primary-400 cursor-pointer"
                (click)="onOffEmoji = !onOffEmoji"
              ></i>
              <emoji-mart
                *ngIf="onOffEmoji"
                set="twitter"
                class="t-emoji"
                (emojiSelect)="addEmoji($event)"
              ></emoji-mart>
            </div>
          </div>
        </div>
      </form>
      <div class="min-h-[150px] w-full px-3 font-inter">
        <div
          class="flex h-full w-full flex-col justify-center gap-1 rounded-xl border border-gray-200 bg-palette-gray-50 py-3"
        >
          <div>
            <div class="text-16 flex w-full items-center gap-1 space-x-1 px-3 font-semibold">
              <div
                class="flex h-[40px] min-h-[40px] min-w-[40px] max-w-[40px] items-center justify-center rounded-full"
              >
                <img
                  class="h-[40px] min-h-[40px] min-w-[40px] max-w-[40px] rounded-full"
                  alt=""
                  src="{{ baseUrl }}/storage/files/thumbnail/{{ dataDetailCar?.avatar.id }}"
                  (error)="replaceWithDefaultImage($event)"
                />
              </div>
              <div>
                <div class="line-clamp-4 text-base font-semibold text-palette-gray-900">
                  <p>{{ dataDetailCar?.vehicle_info?.car_model_name }}</p>
                </div>
                <div class="text-sm font-normal text-palette-gray-500">
                  <p className="text-14 font-normal text-gray-400">Car</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              #hotelDescription
              *ngIf="dataDetailCar?.description"
              class="hotel-description-content px-3 text-sm sm:text-md"
              [ngClass]="isExpanded ? 'line-clamp-none' : 'line-clamp-3'"
            >
              <div
                [innerHTML]="dataDetailCar?.description | localizedLangDescString"
                class="t-rich-text-container"
              ></div>
            </div>
            <button
              *ngIf="isLongText"
              (click)="onExpandText()"
              class="!mt-1 px-3 text-sm font-semibold text-palette-blue-700 hover:underline"
            >
              {{
                isExpanded
                  ? ('COMMON.SHOW_LESS' | soctripTranslate | async)
                  : ('COMMON.VIEW_MORE' | soctripTranslate | async)
              }}
            </button>
            <div class="relative max-h-[150px] pt-2">
              <img
                class="h-full w-full rounded-xl"
                src="{{ baseUrl }}/storage/files/thumbnail/{{ dataDetailCar?.avatar.id }}"
                alt=""
                (error)="replaceWithDefaultImage($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer" class="relative">
    <div class="flex w-full items-center justify-end space-x-3 pt-3">
      <p-dropdown
        [options]="myOptions"
        optionLabel="label"
        class="custom-dropdown-post"
        (onChange)="onChangePriority($event.value.value)"
      >
        <ng-template let-option pTemplate="selectedItem">
          <div class="flex h-10 w-fit items-center space-x-2 pl-2 text-sm">
            <i [ngClass]="option.icon" class="text-xl"></i>
            <span class="font-inter font-medium">
              {{ option.label | soctripTranslate | async }}
            </span>
          </div>
        </ng-template>
        <ng-template let-option pTemplate="item">
          <div class="flex w-full items-center gap-2 !p-2 text-sm">
            <i [ngClass]="option.icon" class="text-xl"></i>
            <span class="font-inter">
              {{ option.label | soctripTranslate | async }}
            </span>
          </div>
        </ng-template>
      </p-dropdown>
      <button
        class="text-white text-sm font-bold py-2 justify-center font-inter rounded-lg h-10 max-w-[100px] min-w-[100px] {{
          shouldDisableButton() ? 'bg-blue-200 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
        }}"
        [disabled]="shouldDisableButton()"
        type="submit"
        form="postForm"
      >
        {{ 'POST.POST' | soctripTranslate | async }}
      </button>
    </div>
  </ng-template>
</p-dialog>
