<div class="t-container container-select-date date-range-picker relative flex h-full w-full gap-1">
  <div class="relative flex h-full w-full flex-col pl-2">
    <div class="text-md font-normal text-[#667085]">{{ 'SEARCH.PLACEHOLDER.CHECK_IN_DATE' | soctripTranslate | async }}</div>
    <div
      class="p-input-icon-left flex items-center"
      (click)="showCalendarDayCheckIn($event)"
      #rangeCalendarCheckIn
    >
      <!-- <i *ngIf="showIcon" class="box-icon-service px-2">
        <i class="sctr-icon-calendar text-xl leading-5 text-palette-blue-600"></i>
      </i> -->
      <!-- [ngClass]="valueCheckIn ? '!w-[95]' : '!w-[110px]'" -->
      <input
        id="check-in-date"
        #selectDateCheckIn
        type="text"
        class="t-input-text truncate font-inter !text-md text-gray-900"
        [ngClass]="{ valueCheckIn: '!w-[110px]' }"
        pInputText
        [(ngModel)]="valueCheckIn"
        (dblclick)="onDatepickerHide()"
        placeholder="{{ 'SEARCH.PLACEHOLDER.CHECK_IN_DATE' | soctripTranslate | async }}"
        readonly
      />
      <!-- (click)="showCalendarDayCheckIn($event)" -->
      <!-- <span class="-ml-2 !text-md font-medium text-gray-900">21:00</span> -->
    </div>
  </div>
  <div class="mr-2 h-[44px] w-[2px] bg-gray-300"></div>

  <div class="relative flex h-full w-full flex-col">
    <div class="text-md font-normal text-[#667085]">{{'SEARCH.PLACEHOLDER.CHECK_OUT_DATE' | soctripTranslate | async }}</div>
    <div
      class="p-input-icon-left flex items-center"
      (click)="showCalendarDayCheckOut($event)"
      #rangeCalendarCheckOut
    >
      <!-- <i *ngIf="showIcon" class="box-icon-service">
        <i class="sctr-icon-calendar text-xl leading-5 text-palette-blue-600"></i>
      </i> -->
      <input
        #selectDateCheckout
        type="text"
        id="check-out-date"
        class="t-input-text truncate font-inter !text-md text-gray-900"
        pInputText
        [(ngModel)]="valueCheckOut"
        (dblclick)="onDatepickerHide()"
        placeholder="{{'SEARCH.PLACEHOLDER.CHECK_OUT_DATE' | soctripTranslate | async }}"
        readonly
      />
      <!-- <span class="-ml-2 !text-md font-medium text-gray-900">20:00</span> -->
    </div>

    <div #rangeCalendarPanel class="custom-overlaypanel absolute top-[58px] bg-slate-500">
      <div
        class="custom-root-element"
        bsDaterangepicker
        [ngClass]=""
        #daterangepicker="bsDaterangepicker"
        [minDate]="currentDate!"
        [maxDate]="maxDate!"
        [bsConfig]="{
          datesDisabled: this.disabledDates,
          showWeekNumbers: false,
          customTodayClass: 'customTodayClass',
          containerClass: 'containerClassCalendar',
          displayMonths: 1,
          adaptivePosition: true,
          }"
        [dateCustomClasses]="customClasses"
        [outsideClick]="false"
        (onHidden)="onDatepickerHide()"
        (onShown)="onDatepickerShown($event)"
        [(bsValue)]="selectedDates"
        (bsValueChange)="onDateRangeChange($event)"
        [style]="{ zIndex: '10000' }"
      ></div>
    </div>
  </div>
</div>
<app-toast></app-toast>
