export const environment = {
  production: false,
  baseURL: 'https://api-dev-tmtrav.tma-swerp.com',
  BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com',
  BASE_API_URL: 'https://api-dev-tmtrav.tma-swerp.com',
  SOCIAL_APP_URL: 'https://tmtrav-dev.tma-swerp.com',
  TRAVEL_ASSIST_URL: 'https://tmtrav-travel-dev.tma-swerp.com/tour',
  FLIGHT_URL: 'https://tmtrav-flight-dev.tma-swerp.com',
  BOOKING_URL: 'https://tmtrav-booking-dev.tma-swerp.com',
  CAR_RENTAL_URL: 'https://tmtrav-car-rental-dev.tma-swerp.com',
  HOTEL_URL: 'https://tmtrav-booking-dev.tma-swerp.com',
  CAR_URL: 'https://tmtrav-car-rental-dev.tma-swerp.com',
  TOUR_URL: 'https://tmtrav-travel-dev.tma-swerp.com/tour',
  ECOMMERCE_FOOD_URL: 'https://tmtrav-food-dev.tma-swerp.com',
  ECOMMERCE_URL: 'https://tmtrav-ecommerce-dev.tma-swerp.com',
  SOCTRIP_CENTER_URL: 'https://tmtrav-business-central-dev.tma-swerp.com/home',
  PROPERTY_DATA_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/booking-property-data',
  RESERVATION_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/booking-reservation',
  RATING_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/rating',
  PROPERTY_SEARCH_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-property-search',
  USER_AND_GUEST_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/booking-user-data',
  FAMOUS_SPOT_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/booking-famous-spot',
  BOOKING_GENERIC_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/booking-generic',
  BOOKMARK_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/bookmarks',
  SHIPMENT_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/shipment',
  TM_TRAV_MAP_LOCATION_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/map-location',
  STORAGE_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/storage',
  STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE: 'https://api-dev-tmtrav.tma-swerp.com/storage/files/web',
  PROPERTY_ROOM_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/booking-property-room',
  BOOKING_PRICING_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/booking-pricing',
  BOOKING_USER_DATA_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/booking-user-data',
  WALLET_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/ecommerce-wallet',
  FINANCE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/ecommerce-finance',
  CARRENT_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/car-rental',
  CARRENT_ORDER_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/car-rental-order',
  CARRENT_OTA_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/car-rental-ota',
  CARRENT_PROMOTION_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/car-rental-promotion',
  MAP_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/map-location',
  REGIONAL_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/regional',
  BANNER_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/banner',
  PAYMENT_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/payment',
  APPROVAL_URL: 'https://approval-tmtrav-dev.tma-swerp.com',
  TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',
  USER_ROLE_KEY: 'user_role',
  REFRESH_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  ACCESS_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  AUTH_STATUS: 'auth_status',
  USER_PROFILE: 'user_profile',
  LANGUAGE_PREFERENCE: 'languagePreference',
  support_languages: ['en', 'vi'],
  support_currency: ['USD', 'VND'],
  USER_UUID: 'user_uuid_generate',
  ENVIRONMENT: 'dev',
  PRODUCT_NAME: 'Soctrip',
  SESSION_ID: 'session_id',
  MODULE: 'CAR_RENTAL',
  socialAuthenIds: {
    google: '802332645501-0aiir74m36114t6v974bcrg4jrf8k1do.apps.googleusercontent.com',
    facebook: '2619489051654463',
  },
  REVIEW_CONTRACT_PDF:
    'https://api-dev-tmtrav.tma-swerp.com/storage/files/download/vn_20240425T114940935148034_00000000-0000-0000-0000-000000000000_term',
  REVIEW_CONTRACT_PDF_VI:
    'https://api-dev-tmtrav.tma-swerp.com/storage/files/download/vn_20240503T112415545305204_00000000-0000-0000-0000-000000000000_soctripconditionsvi',
  VOUCHER_ENABLED: true,
  CURRENCY_CONVERSATION_DATA: 'currency-conversation-data',
  OTA_CHECK_TIME_ACTION: false,
  CAR_OWNER_VISIBLE: true,
  prefix: 'booking/car',
  HELP_CENTER_URL: 'https://help.soctrip.com',
  UN_AUTH_LANG: 'unauthLang',
  META_CODE: 'G-PQBCBB9BDP',
  DEVICE_ID: 'device_id',
  SOCCHAT_VISIBLE: true,
  CHAT_MODULE: 'CAR',
  MAP_URL: 'https://tmtrav-map-resource-dev.tma-swerp.com',
  MAP_ROUTING_URL: 'https://tmtrav-map-dev.tma-swerp.com',
  ADMIN_URL: 'https://tmtrav-travel-admin-dev.tma-swerp.com',
};
