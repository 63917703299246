import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchBarDataService {
  private _searchBarData: ReplaySubject<ISearchBarData> = new ReplaySubject();
  private searchBarData$: Observable<ISearchBarData> =
    this._searchBarData.asObservable();
  constructor() {}
  setSearchData(data: ISearchBarData) {
    this._searchBarData.next(data);
  }
  getSearchData() {
    return this.searchBarData$;
  }
}

export interface ISearchBarData {
  destination: string | null;
  // adults: number | null;
  // children: number | null;
  // child_ages_list: string[] | null;
  rooms: number | null;
  check_in: string | null;
  check_out: string | null;
  currency: string | null;
  country_code: string | null;
  lat: number;
  lon: number;
  place_id: string;
  geo_type: string;
}
