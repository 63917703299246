import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  DoCheck,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import {
  BsDatepickerDirective,
  BsDaterangepickerContainerComponent,
  DatepickerDateCustomClasses,
  BsDatepickerConfig,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
} from 'rxjs';
import { MessageService } from 'primeng/api';
import { RecommendPlaceRequest } from '@modules/car-booking/models/recommendPlaces.model';
import moment from 'moment';
import { SelectedRange } from '@shared/models/generic.model';
import { OrderControllerService } from '@car-rental/angular-car-rental-order-service';
import { ShortCurrencyPipe } from '@shared/pipes/short-currency.pipe';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { viLocale } from '../../../../assets/i18n/vi';
import { DateTime } from 'luxon';
import { CustomDateTimeService } from '@shared/services/custom-date-time.service';
import { DateFormat } from '@shared/enum/date-format.enum';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import { ActivatedRoute } from '@angular/router';
import { TzOnlyPipe } from '@shared/pipes/tz-only.pipe';
defineLocale('vi', viLocale);

export interface ICalendarPrice {
  date: Date;
  price: string;
  days: string[];
}

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

@Component({
  selector: 'app-calendar-select',
  templateUrl: './calendar-select.component.html',
  styleUrls: ['./calendar-select.component.scss'],
  providers: [MessageService, TzOnlyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppCalenderSelectComponent
  implements OnChanges, OnDestroy, DoCheck, OnInit, AfterViewInit
{
  @ViewChild('selectDateCheckIn') selectDateCheckIn!: ElementRef;
  @ViewChild('selectDateCheckout') selectDateCheckout!: ElementRef;
  @ViewChild('rangeCalendarCheckIn') rangeCalendarCheckIn: ElementRef;
  @ViewChild('rangeCalendarCheckOut') rangeCalendarCheckOut: ElementRef;
  @ViewChild('daterangepicker', { static: false })
  daterangepicker: BsDatepickerDirective;
  @ViewChild('bsDaterangepicker', { read: ElementRef })
  bsDaterangepicker: ElementRef;

  @Input() valueCheckIn: any = '';
  @Input() valueCheckOut: any = '';
  @Input() priceList: Array<ICalendarPrice> = [];
  @Output() selectDateValue = new EventEmitter<SelectedRange>();
  @Input() disabledDates: Date[] = [];
  @Input() showIcon: boolean = true;
  @Input() bookedList: { end_date: Date }[] = [];
  @Output() changeMonth = new EventEmitter<Date>();
  @Input() timeZones: string | undefined = '';
  private searchModelChangeSubscription: Subscription;
  bsConfig: Partial<BsDatepickerConfig>;
  lastKnownViewDate: Date;
  // pipe = new DatePipe('en-US');
  currentDate: Date = new Date(Date.now());
  maxDate: Date | null;
  startDate: Date;
  tempDateCheckIn: string | null;
  tempDateCheckOut: string | null;
  // searchLocation: string = '';
  // searchText: string = '';
  // searchModelChanged: Subject<string> = new Subject<string>();
  selectedDates: (Date | undefined)[] | undefined;
  isFocused: boolean = false;
  isLoading: boolean = false;
  onDestroy$: Subject<void> = new Subject();
  // isClickedInput = false;
  customClasses: DatepickerDateCustomClasses[];
  selectedLanguage = localStorage.getItem('user_profile');
  currencyUnit = this.shortCurrencyPipe.transform(1, true);
  customElement: string = '';
  disableList: string[] = [];
  constructor(
    private orderService: OrderControllerService,
    private shortCurrencyPipe: ShortCurrencyPipe,
    private localeService: BsLocaleService,
    private customDateTimeService: CustomDateTimeService,
    private soctripTranslationService: SoctripTranslationService,
    private route: ActivatedRoute,
    private timeZonePipe: TzOnlyPipe
  ) {}

  ngOnInit(): void {
    //this._OnInit();
    // this.handleSearchPlaces();
    // this.customClass = [
    //   { classes: ['custom-date'], date: new Date() },
    //   { classes: ['custom-date'], date: new Date('2/2/2024') },
    // ];
    // //console.log(this.customClass);

    this.route.queryParams.subscribe((params) => {
      let startDate;
      let endDate;
      if (params['checkin']) {
        startDate = new Date(params['checkin']);
      }
      if (params['checkout']) {
        endDate = new Date(params['checkout']);
      }
      this.selectedDates = [startDate, endDate];
    });

    if (this.selectedLanguage !== null) {
      const userProfile = JSON.parse(this.selectedLanguage);
      if (
        typeof userProfile === 'object' &&
        'language' in userProfile &&
        (userProfile.language as string).toLocaleLowerCase() === 'vi'
      ) {
        this.localeService.use('vi');
      } else {
        this.localeService.use('en');
      }
    } else {
      this.localeService.use('en');
    }
    // this.bsConfig = {
    //   datesDisabled: this.disabledDates,
    //   showWeekNumbers: false,
    //   customTodayClass: 'customTodayClass',
    //   containerClass: 'containerClassCalendar',
    //   displayMonths: 1,
    //   adaptivePosition: true,
    // };
    this.orderService;
  }

  ngAfterViewInit(): void {
    this.updateCustomElement();
  }

  // _OnInit() {
  //   this.valueCheckIn = this.pipe.transform( this.valueCheckIn, 'dd/MM/yyyy');
  //   this.valueCheckOut = this.pipe.transform( this.valueCheckOut, 'dd/MM/yyyy');
  //  }
  ngDoCheck(): void {
    this.mapDate();
  }

  // genCustomNode(index: number) {
  //   const node = document.createElement('div');
  //   // node.innerText = !!this.priceList[index]?.price
  //   //   ? '$' + this.priceList[index]?.price
  //   //   : '';
  //   node.innerText = '2222';

  //   node.classList.add(
  //     'child',
  //     'absolute',
  //     'text-[8px]',
  //     'text-[#d92d20]',
  //     'top-3',
  //     'left-1/2',
  //     '-translate-x-1/2',
  //     'font-medium',
  //     'block',
  //     'h-0'
  //   );
  //   return node;
  // }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes['receivedData']) {
    //   this._OnInit();
    // }
    if (changes['valueCheckIn']) {
      if (this.valueCheckIn != 'Invalid Date') {
        this.valueCheckIn = this.customDateTimeService.formatDate(
          this.valueCheckIn,
          DateFormat.DATE_SHORT
        );
      } else {
        var today = new Date();
        var tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        this.valueCheckIn = this.customDateTimeService.formatDate(tomorrow, DateFormat.DATE_SHORT);
      }

      //this.tempDateCheckIn = this.pipe.transform(this.valueCheckIn, 'dd/MM/yyyy');
    }

    if (changes['valueCheckOut']) {
      if (this.valueCheckOut != 'Invalid Date') {
        this.valueCheckOut = this.customDateTimeService.formatDate(
          this.valueCheckOut,
          DateFormat.DATE_SHORT
        );
      } else {
        var today = new Date();
        var tomorrow = new Date(today.getTime() + 2 * 24 * 60 * 60 * 1000);
        this.valueCheckOut = this.customDateTimeService.formatDate(tomorrow, DateFormat.DATE_SHORT);
      }
      //this.tempDateCheckOut = this.pipe.transform(this.valueCheckOut, 'dd/MM/yyyy');
    }
    if (changes['priceList'] && this.priceList?.length > 0) {
      this.customClasses = [...(this.priceList ?? [])]
        .sort((a, b) => moment(a.date).toDate().getTime() - moment(b.date).toDate().getTime())
        .filter((item) => {
          const day = moment(item.date).day();
          const isHasDay = item.days?.includes(DAYS[day]);
          return isHasDay;
        })
        .filter((item) => {
          const today = moment().startOf('day');
          const itemDate = moment(item.date).startOf('day');
          return itemDate.isSameOrAfter(today);
        })
        .map((price, index) => ({
          classes: ['custom-date', 'custom-date_' + index],
          date: price.date,
          price: price.price,
          format: moment(price.date).format(),
        }));
    }
  }

  // handleSearchPlaces() {
  //   this.searchModelChangeSubscription = this.searchModelChanged
  //     .pipe(debounceTime(500), distinctUntilChanged())
  //     .subscribe((searchText: string) => {
  //       const params: RecommendPlaceRequest = {
  //         keyword: searchText,
  //       };
  //       this.searchText = searchText;
  //       this.isClickedInput = false;
  //     });
  // }

  ngOnDestroy(): void {
    this.searchModelChangeSubscription?.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const dateRangePickerInputs = document.querySelectorAll('.date-range-picker');
    let clickedInsidePicker = false;

    const dateRangePickerArray = Array.from(dateRangePickerInputs);
    for (const input of dateRangePickerArray) {
      if (input.contains(targetElement)) {
        clickedInsidePicker = true;
        break;
      }
    }

    if (!clickedInsidePicker) {
      // const element = document.querySelector('.bs-datepicker') as HTMLElement;
      this.daterangepicker.hide();
    }
  }

  //#endregion
  formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format();

  onDateRangeChange(event: any) {
    const startDate = DateTime.fromJSDate(event[0]);
    const endDate = DateTime.fromJSDate(event[1]);

    if (event && startDate.isValid && endDate.isValid) {
      const selectedRange: SelectedRange = {
        start_date: event[0],
        end_date: event[1],
      };
      this.selectDateValue.emit(selectedRange);

      this.startDate = event[0];
      this.valueCheckIn = this.customDateTimeService.formatDate(startDate, DateFormat.DATE_SHORT);
      this.tempDateCheckIn = this.customDateTimeService.formatDate(
        startDate,
        DateFormat.DATE_SHORT
      );

      this.valueCheckOut = this.customDateTimeService.formatDate(endDate, DateFormat.DATE_SHORT);
      this.tempDateCheckOut = this.customDateTimeService.formatDate(endDate, DateFormat.DATE_SHORT);
      this.maxDate = null;
    }
  }

  updateCustomElement() {
    this.customElement = `
    <div class="flex flex-col">
      <div class="flex items-center -mt-5 px-5 pb-1 text-sm text-gray-700">
      ${this.soctripTranslationService.getTranslation('DETAIL.currency_unit')}: ${
      this.currencyUnit
    } | ${this.soctripTranslationService.getTranslation(
      'DETAIL.time_zone'
    )}: ${this.timeZonePipe.transform(this.timeZones!)}</div>
    <div class="flex items-center px-5 pb-3 text-xs text-gray-700 gap-2 ">
        <p class="w-[8px] h-[8px] rounded-[50%] top-[1px] left-1/2 bg-palette-yellow-400 -z-10"></p>
        <p>${this.soctripTranslationService.getTranslation('DETAIL.end_date_warning')}</p>
      </div>
    </div>`;
  }

  showCalendarDayCheckIn(event: Event) {
    this.daterangepicker.hide();
    this.daterangepicker.minDate = new Date(Date.now());
    this.daterangepicker.show();
    this.valueCheckIn = null;
    const element = document.querySelector('.bs-datepicker') as HTMLElement;
    element.style.display = 'block';
    // const elements = document.querySelector('bs-daterangepicker-container') as HTMLElement;

    this.updateCustomElement();
    const currencyUnitElement = document.querySelector('.bs-datepicker-container');
    currencyUnitElement!.insertAdjacentHTML('beforeend', this.customElement);
  }

  showCalendarDayCheckOut(event: Event) {
    this.daterangepicker.hide();
    this.daterangepicker.show();
    const element = document.querySelector('.bs-datepicker') as HTMLElement;
    element.style.display = 'block';
    // const elements = document.querySelector('bs-daterangepicker-container') as HTMLElement;

    this.updateCustomElement();
    const currencyUnitElement = document.querySelector('.bs-datepicker-container');
    currencyUnitElement!.insertAdjacentHTML('beforeend', this.customElement);
  }

  onDatepickerHide() {
    if (this.valueCheckIn && !this.valueCheckOut) {
      const selectedRange: SelectedRange = {
        start_date: this.currentDate,
        end_date: this.currentDate,
      };
      this.selectedDates = [selectedRange.start_date, selectedRange.end_date];
      this.tempDateCheckIn = this.valueCheckIn;
      this.tempDateCheckOut = this.valueCheckIn;
      this.valueCheckOut = this.valueCheckIn;
      this.maxDate = null;
    } else {
      this.valueCheckIn = this.tempDateCheckIn;
      this.valueCheckOut = this.tempDateCheckOut;
      this.maxDate = null;
      this.currentDate = new Date(Date.now());
    }
  }

  mapDate() {
    this.disableList = this.disabledDates.map((item) => item.toISOString().split('T')[0]);
    this.customClasses?.forEach((cl) => {
      const item = document.querySelector('.' + cl.classes[1]) as Element;
      if (item) {
        const isSelectStart = item.classList.contains('select-start');
        const isSelectEnd = item.classList.contains('select-end');

        item.querySelector('.child')?.remove();
        item.classList.add('relative');

        const node = document.createElement('div');
        const node2 = document.createElement('div');
        node.innerText = (cl as any).price;
        node.classList.add(
          'child',
          'absolute',
          'text-[10px]',
          'top-3',
          'left-1/2',
          '-translate-x-1/2',
          'font-medium',
          'block',
          '-z-10'
        );

        this.bookedList.forEach((range) => {
          const endDate = range.end_date.toISOString().split('T')[0];
          const currDate = cl.date.toISOString().split('T')[0];
          if (currDate === endDate && !this.disableList.includes(endDate)) {
            node2.classList.add(
              'absolute',
              'w-[5px]',
              'h-[5px]',
              'rounded-[50%]',
              'top-[1px]',
              'left-1/2',
              '-translate-x-1/2',
              'bg-palette-yellow-400',
              '-z-10'
            );
          }
        });

        if (isSelectStart || isSelectEnd) {
          node.classList.add('text-white');
        } else {
          node.classList.add('text-[#d92d20]');
        }

        node.style.height = '0px';
        item.appendChild(node);
        item.appendChild(node2);
      }
    });
  }

  onDatepickerShown(container: any): void {
    this.mapDate();

    // const dayHoverHandler = container.dayHoverHandler;
    // const hoverWrapper = ($event: any) => {
    //   const { cell, isHovered } = $event;
    //   // do whatever with hovered cell/event
    //   return dayHoverHandler($event);
    // };
    // container.dayHoverHandler = hoverWrapper;
    this.selectDateCheckIn.nativeElement.focus();
    const datePickerInstance: BsDaterangepickerContainerComponent =
      this.daterangepicker['_datepicker'].instance;
    datePickerInstance.valueChange
      .pipe(filter((value) => value && value[0] && !value[1]))
      .subscribe((value: Date[]) => {
        if (value) {
          const [startDate] = value;
          const startDateTime = DateTime.fromJSDate(startDate);
          this.valueCheckIn = this.customDateTimeService.formatDate(
            startDateTime,
            DateFormat.DATE_SHORT
          );
          this.currentDate = startDate;
          this.maxDate = startDateTime.plus({ days: 90 }).toJSDate();
          this.selectDateCheckout.nativeElement.focus();
          this.valueCheckOut = null;
        }
      });
    const store = container._store.source as BehaviorSubject<BsDatePickerStoreData>;
    store.subscribe((data) => {
      const date = data.view.date as Date;
      if (
        !this.lastKnownViewDate ||
        this.lastKnownViewDate.toDateString() !== date.toDateString()
      ) {
        if (data.view.mode === 'day') {
          if (date.getMonth() !== this.lastKnownViewDate?.getMonth()) {
            this.lastKnownViewDate = date;
            this.changeMonth.emit(date);
          }
        }
      }
    });
  }

  // convertDateDefault(date: any) {
  //   const originalDate = new Date(date);
  //   const year = originalDate.getFullYear();
  //   const month = String(originalDate.getMonth() + 1).padStart(2, '0');
  //   const day = String(originalDate.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // }
}
interface BsDatePickerStoreView {
  date: Date;
  mode: 'day' | 'month' | 'year';
}
interface BsDatePickerStoreData {
  view: BsDatePickerStoreView;
}
