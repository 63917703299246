<div class="toast-booking">
  <p-toast position="top-right" [key]="'save'">
    <ng-template let-message pTemplate="message">
      <div class="flex flex-col" style="flex: 1">
        <div class="flex gap-3">
          <i
            class="font-semibold"
            [ngClass]="[
              message.severity === 'success' ? 'sctr-icon-check-circle' : 'sctr-icon-alert-circle'
            ]"
            style="font-size: 20px"
          ></i>
          <p class="pb-2 text-md font-medium text-black">
            {{ message.detail }}
          </p>
        </div>
        <div class="pl-[20px]">
          <button
            class="px-[12px] py-[4px] hover:rounded-lg hover:border-solid hover:bg-[#EFF8FF]"
            (click)="openDialog()"
          >
            <p class="text-primary-600 text-md font-semibold">
              {{ 'toast_save_car.change' | translate }}
            </p>
          </button>
        </div>
      </div>
    </ng-template>
  </p-toast>
</div>
<div
  class="hot-deal-item flex cursor-pointer flex-col rounded-lg border font-inter"
  (click)="viewDetails(item.id, item)"
>
  <div class="image-container h-[152px]">
    <img
      class="aspect-[3/2] h-[152px] w-full rounded-t-lg object-cover"
      src="{{ imageUrl }}"
      (error)="replaceWithDefaultImage($event)"
      alt="image"
      data-cy="url-img-hotel"
    />
    <!-- <img *ngIf="!item.avatar" class="rounded-lg aspect-[16/9] w-full max-h-[118px] object-cover" src="{{baseImageId}}/{{ item.property_image_overview_id }}.webp" (error)="replaceWithDefaultImage($event)" alt=" " data-cy="url-img-hotel"> -->
    <button
      type="button"
      data-cy="btn-favorite"
      class="btn-favorite absolute right-2 top-2 rounded-lg p-[6px] text-center"
      (click)="handleBookmark($event, item)"
    >
      <span *ngIf="isBookmark; then favorite; else unfavorite"></span>
      <ng-template #favorite>
        <img class="!h-4 !w-4" src="assets/images/common-icon/bookmark-close.svg" alt="" />
      </ng-template>
      <ng-template #unfavorite>
        <img class="!h-4 !w-4" src="assets/images/common-icon/bookmark-open.svg" alt="" />
      </ng-template>
    </button>
    <!-- <div
      class="bg-attribute absolute left-2 top-2 flex items-center gap-1 px-2 py-1"
    >
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.20346 1.06205C6.23119 0.841796 6.10936 0.629762 5.9043 0.541377C5.69923 0.452993 5.46003 0.509419 5.31691 0.679936L0.820222 6.03761C0.742512 6.13017 0.663802 6.22391 0.606494 6.30629C0.552296 6.38421 0.453339 6.53739 0.450281 6.73872C0.446781 6.9692 0.550222 7.18849 0.730793 7.33337C0.888522 7.45992 1.0704 7.48251 1.16538 7.49105C1.2658 7.50008 1.38872 7.50005 1.51007 7.50003L4.62975 7.50003L4.19693 10.938C4.1692 11.1583 4.29103 11.3703 4.49609 11.4587C4.70116 11.5471 4.94036 11.4906 5.08348 11.3201L9.58017 5.96245C9.65788 5.86989 9.73659 5.77615 9.7939 5.69376C9.84809 5.61585 9.94705 5.46266 9.95011 5.26134C9.95361 5.03085 9.85017 4.81157 9.6696 4.66669C9.51187 4.54013 9.32999 4.51755 9.23501 4.50901C9.13459 4.49998 9.01166 4.5 8.8903 4.50003L5.77064 4.50003L6.20346 1.06205Z"
          fill="#FDB022"
        />
      </svg>

      <div class="text-[12px] font-medium leading-4 text-white">
        Quick car rental
      </div>
    </div> -->
  </div>
  <div class="flex h-fit flex-col gap-2 rounded-lg bg-white !p-3">
    <div class="flex flex-col gap-2">
      <div
        #hotelName
        [tooltip]="item.vehicle_info?.car_model_name + ' ' + item.vehicle_info?.year_model"
        [isTruncated]="true"
        [position]="TooltipPosition.BELOW"
        class="line-clamp-1 h-[20px] text-[14px] font-semibold leading-5 text-palette-gray-900"
        data-cy="name-hotel"
      >
        {{ item.vehicle_info?.car_model_name }} {{ item.vehicle_info?.year_model }}
      </div>
      <div class="flex h-[44px] w-full flex-wrap gap-1 overflow-hidden">
        <span
          *ngFor="let tag of tagList"
          class="flex h-min items-center justify-center rounded-full px-1.5 py-0.5 text-[12px] font-medium leading-4 {{
            tag.type
          }}"
        >
          {{ tag.name }}
        </span>
      </div>
      <div class="relative flex items-center">
        <common-icon-text
          class="flex !w-full items-center overflow-hidden text-ellipsis"
          [icon]="'sctr-icon-marker-pin-01'"
          [iconStyle]="'text-base !leading-5 mr-1 opacity-70'"
          [text]="getAddress(item)"
          [textStyle]="
            'text-soctrip-small font-normal text-palette-gray-500 whitespace-nowrap text-ellipsis overflow-hidden'
          "
          [dataCyValue]="'address-hotel'"
        ></common-icon-text>
      </div>
      <!-- <div
        class="grid h-4 grid-cols-3 gap-2 [&>*:nth-child(n+9)]:hidden"
        data-cy="hotel_facility_list"
      >
        <div class="icon-service col-span-1 flex h-4 items-center">
          <img src="assets/icons/like.svg" alt="MarkerPin" />
          <span
            class="text-soctrip-small ml-1 overflow-hidden text-ellipsis font-normal text-palette-gray-500"
            >{{ item.sum_review }}</span
          >
        </div>
        <div class="icon-service col-span-1 flex h-4 items-center">
          <img src="assets/icons/trips.svg" alt="MarkerPin" />
          <span
            class="text-soctrip-small ml-1 overflow-hidden text-ellipsis font-normal text-palette-gray-500"
            >{{ item.trips }}</span
          >
        </div>
      </div> -->
    </div>
    <div *ngIf="originPrice || price" class="flex min-h-14 w-full flex-col items-end justify-end">
      <app-price-format
        *ngIf="originPrice > price"
        [keepValue]="userCurrency === item.currency"
        [price]="originPrice"
        [styleClass]="
          'text-palette-gray-500 text-[14px] font-normal whitespace-nowrap text-ellipsis overflow-hidden line-through truncate'
        "
      ></app-price-format>
      <div class="flex items-center">
        <app-price-format
          [keepValue]="userCurrency === item.currency"
          [price]="price"
          [styleClass]="'text-base font-medium !leading-6 truncate text-palette-orange-dark-500'"
        ></app-price-format>
        <span
          class="text-soctrip-small overflow-hidden text-ellipsis font-normal text-palette-gray-500"
          >/{{ 'COMMON.DAY' | translate }}</span
        >
      </div>
    </div>
  </div>
</div>
<app-toast keyToast="hd"></app-toast>
<app-dialog-save-list-car
  [visible]="visible"
  [detailId]="item.id"
  [key]="'hd'"
  (disable)="closeDialog()"
></app-dialog-save-list-car>
